//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Hotline from "@/components/hotline.vue";
import Address from "@/components/address.vue";
export default {
  components: {
    Hotline,
    Address,
  },
  data() {
    return {
      is_index: -1,
      list: [
        {
          name: "品牌故事",
          url: "/story",
        },
        {
          name: "产品中心",
          url: "/product",
        },
        {
          name: "招商代理",
          url: "/agent",
        },
        {
          name: "联系我们",
          url: "/contact",
        },
      ],
    };
  },
  created() {},
  methods: {
    text_click(url, index) {
      this.is_index = index;
      this.$router.push(url);
    },
  },
};
