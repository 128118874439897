import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: home,
    children: [{
        path: '/',
        name: 'website',
        component: () => import('@/views/home/website.vue')
      }, {
        path: '/story',
        name: 'story',
        component: () => import('@/views/home/story.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/home/contact.vue')
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('@/views/home/product.vue')
      },
      {
        path: '/agent',
        name: 'agent',
        component: () => import('@/views/home/agent.vue')
      },

    ]
  },

]
const router = new VueRouter({
  routes
})

export default router